export const createCookie = (name: string, value: string, domain: string) => {
  return `${name}=${value}; Path=/; Domain=${domain}; Max-Age=604800; SameSite=None; Secure;`
}

export const getCookie = (name: string): string | null => {
  const regex = new RegExp(`(^| )${name}=([^;]+)`)
  const match = regex.exec(document.cookie)
  return match ? decodeURIComponent(match[2]) : null
}

export const deleteCookie = (name: string, domain: string, secureFlag: string) => {
  document.cookie = `${name}=; Domain=${domain}; Max-Age=0; Path=/; SameSite=None; Secure;`
}
